import {Timestamp} from '@firebase/firestore';

import {Address} from './address';
import {CreatedAt} from './created-at';
import {CreatedAtFirestore} from './created-at-firestore';
import {UpdatedAt} from './updated-at';
import {UpdatedAtFirestore} from './updated-at-firestore';
import {User} from './user';

export interface Order extends OrderPartialDocument, OrderAttachments, OrderDates<Date>, CreatedAt, UpdatedAt {}

export interface OrderDocument
  extends OrderPartialDocument,
    OrderAttachmentsDocument,
    OrderDates<Timestamp>,
    CreatedAtFirestore,
    UpdatedAtFirestore {}

/**
 t('models.order.status_type.unknown')
 t('models.order.status_type.cancelled')
 t('models.order.status_type.delivered')
 t('models.order.status_type.unsubscribed')
 t('models.order.status_type.completed')
 */
export enum OrderStatus {
  CANCELLED = 'cancelled',
  DELIVERED = 'delivered',
  UNSUBSCRIBED = 'unsubscribed',
  COMPLETED = 'completed',
}

export const ORDER_STATUS_ORDER = [OrderStatus.COMPLETED, OrderStatus.UNSUBSCRIBED, OrderStatus.DELIVERED, OrderStatus.CANCELLED];

/**
 t('models.order.service_frequencies.unknown')
 t('models.order.service_frequencies.weekly')
 t('models.order.service_frequencies.biweekly')
 */
export enum OrderServiceFrequency {
  WEEKLY = 'weekly',
  BIWEEKLY = 'biweekly',
}

interface OrderDates<T> {
  shippedAt: T;
  rentFrom: T;
  rentTo: T | null;
}

/**
 * t(`models.order.position.no`)
 * t(`models.order.position.name`)
 * t(`models.order.position.code`)
 */
interface OrderPosition {
  name: string;
  code: string;
}

type OrderPositions = Record<number, OrderPosition>;

/**
 * t(`models.order.attachment.id`)
 * t(`models.order.attachment.date`)
 * t(`models.order.attachment.file_name`)
 * t(`models.order.attachment.comment`)
 */
interface OrderAttachmentPartial<D extends Date | Timestamp> {
  id: string;
  date: D;
  fileName: string;
  comment: string;
}

type OrderAttachmentsPartial<OA extends OrderAttachmentPartial<Date | Timestamp>> = Record<'attachments', Record<string, OA>>;

type OrderAttachment = OrderAttachmentPartial<Date>;
type OrderAttachments = OrderAttachmentsPartial<OrderAttachment>;

type OrderAttachmentDocument = OrderAttachmentPartial<Timestamp>;
type OrderAttachmentsDocument = OrderAttachmentsPartial<OrderAttachmentDocument>;

/**
 * t(`models.order.customer_id`)
 * t(`models.order.user`)
 * t(`models.order.reference`)
 * t(`models.order.positions`)
 * t(`models.order.address`)
 * t(`models.order.service_frequency`)
 * t(`models.order.construction_site_no`)
 * t(`models.order.toilet_no`)
 * t(`models.order.comment`)
 */
interface OrderPartialDocument {
  customerId: string; // CardCode
  userId: string; // ContactCode
  user: Partial<Pick<User, 'firstName' | 'lastName'>>;
  status: OrderStatus;
  reference: string;
  positions: OrderPositions;
  address: Address;
  serviceFrequency: OrderServiceFrequency;
  constructionSiteNo: string; // YourReference
  toiletNo: string; // WCNumber
  comment: string;
  isUnsubscribed: boolean;
}
